export const songs = [
  { name: "2012", id: "https://open.spotify.com/embed/track/4UFGBYbb7ixis7fTdzQx0h?utm_source=generator" },
  { name: "sing to me", id: "https://open.spotify.com/embed/track/6QbM2D1CI7cbt6c8G1d3EL?utm_source=generator" },
  { name: "most wanted", id: "https://open.spotify.com/embed/track/1UP7PXne1lIYZB5G5aiRGL?utm_source=generator" },
  { name: "avocado shake", id: "https://open.spotify.com/embed/track/3sDkuBJVMkMOQ70MK8y7yN?utm_source=generator" },
  { name: "sigil and the maze", id: "https://open.spotify.com/embed/track/19DDSrBVhLk9GWP9arwCAZ?utm_source=generator" },
  { name: "welcome change", id: "https://open.spotify.com/embed/track/5ua4QkJQDqWjfsbJDNCC1X?utm_source=generator" },
  { name: "magnolia", id: "https://open.spotify.com/embed/track/43sdRsmJFcSnlTWTVRhYtz?utm_source=generator" },
  { name: "i want you around", id: "https://open.spotify.com/embed/track/2gjidmxtA1pyj2HYrdOTG1?utm_source=generator" },
  { name: "luv u", id: "https://open.spotify.com/embed/track/7gSgudVRXpsMrPLaSDwemo?utm_source=generator" },
  { name: "take it easy", id: "https://open.spotify.com/embed/track/4yugZvBYaoREkJKtbG08Qr?utm_source=generator" },
  { name: "without", id: "https://open.spotify.com/embed/track/0TxQOJfZ8GgotoZWbhqi5Y?utm_source=generator" },
  { name: "dreamflower", id: "https://open.spotify.com/embed/track/4iyH37AHAkYK1MVhveeqcm?utm_source=generator" },
  { name: "didn't cha know", id: "https://open.spotify.com/embed/track/7pv80uUHfocFqfTytu1MVi?utm_source=generator" },
  { name: "ottolenghi", id: "https://open.spotify.com/embed/track/64I9byMYBlS1ARsC3vtpgW?utm_source=generator" },
  { name: "maple syrup", id: "https://open.spotify.com/embed/track/6R7qTBqJ0FRL3XYgXVStCR?utm_source=generator" },
  { name: "grounded", id: "https://open.spotify.com/embed/track/3Fy2uMZm0H6WbWhkXGjbmJ?utm_source=generator" },
  { name: "sirius", id: "https://open.spotify.com/embed/track/3GYH6TqF2fIihKps1Sedxc?utm_source=generator" },
  { name: "slow down", id: "https://open.spotify.com/embed/track/73FDJgnjt6ZcxoiavN6XrS?utm_source=generator" },
  { name: "so in love with you", id: "https://open.spotify.com/embed/track/7yLQMOBHYEkFkFQcEoNEpR?utm_source=generator" },
  { name: "nothing even matters", id: "https://open.spotify.com/embed/track/1Q0lOGB52skgfzdcq233vs?utm_source=generator" },
  { name: "ice water", id: "https://open.spotify.com/embed/track/5wXZXabu8JyRGNHqgueUHm?utm_source=generator" },
  { name: "i choose you", id: "https://open.spotify.com/embed/track/6GEq2FrZksbrO3otMV9ZDh?utm_source=generator" },
  { name: "august 10", id: "https://open.spotify.com/embed/track/4VBR3KptORIDW9dRAeLpCn?utm_source=generator" },
  { name: "wild irish roses", id: "https://open.spotify.com/embed/track/6efkcs2aUBMFKxl0cl2JWQ?utm_source=generator" },
  { name: "astrovan", id: "https://open.spotify.com/embed/track/14e46mURUUwB3i2vTe1gFz?utm_source=generator" },
  { name: "hung up on my baby", id: "https://open.spotify.com/embed/track/0xQlC8tI2hBeOs2TKOkM1G?utm_source=generator" },
  { name: "we've got to try", id: "https://open.spotify.com/embed/track/3MjX8uiZYKRuuELm6iyunN?utm_source=generator" },
  { name: "in your atmosphere", id: "https://open.spotify.com/embed/track/0EUSbTvyZfo9QOcxunL9Ro?utm_source=generator" },
  { name: "44 caliber love letter", id: "https://open.spotify.com/embed/track/6qrHyMUSJW9cHOP7VtxRER?utm_source=generator" },
  { name: "desire", id: "https://open.spotify.com/embed/track/7KkpZSHhQXcecsB0Hgu4Wd?utm_source=generator" },
  { name: "family business", id: "https://open.spotify.com/embed/track/6wFxUq7Gr44N3uwLjVQRR0?utm_source=generator" },
  { name: "japanese denim", id: "https://open.spotify.com/embed/track/1boXOL0ua7N2iCOUVI1p9F?utm_source=generator" },
  { name: "foldin clothes", id: "https://open.spotify.com/embed/track/77IAeEz8LEchPN8UNjaTJ2?utm_source=generator" },
  { name: "no mountain high enough", id: "https://open.spotify.com/embed/track/1LeWIs2hP2r5yOQnVuYoI5?utm_source=generator" },
  { name: "redbone", id: "https://open.spotify.com/embed/track/47l9wxr6RwgoTSfnseBRcf?utm_source=generator" },
  { name: "yeah yeah yeah yeah", id: "https://open.spotify.com/embed/track/2cDCojn6uIBM6A5xTAbl3H?utm_source=generator" },
  { name: "do you like drugs", id: "https://open.spotify.com/embed/track/4THgRPmLvhNmM9Lzj8qrvH?utm_source=generator" },
  { name: "thinkin bout you", id: "https://open.spotify.com/embed/track/7DfFc7a6Rwfi3YQMRbDMau?utm_source=generator" },
  { name: "love speakeasy", id: "https://open.spotify.com/embed/track/6eXpxFGhRMssC5XnaxEhMu?utm_source=generator" },
  { name: "luther", id: "https://open.spotify.com/embed/track/2CGNAOSuO1MEFCbBRgUzjd?utm_source=generator" },
  { name: "harvest moon", id: "https://open.spotify.com/embed/track/7sCHy8QQUz3CRvUMp53Cbm?utm_source=generator" },
  { name: "feels like summer", id: "https://open.spotify.com/embed/track/7p4vHnYXkxlzvfePJVpcTr?utm_source=generator" },
  { name: "brown skin lady", id: "https://open.spotify.com/embed/track/3Mz4AoWIedMfzCib1LYwMZ?utm_source=generator" },
  { name: "roses", id: "https://open.spotify.com/embed/track/6bUNEbXT7HovLW6BgPCBsb?utm_source=generator" },
  { name: "that's what i love", id: "https://open.spotify.com/embed/track/33vtLeCWJAMbYjIg6xc6cz?utm_source=generator" },
  { name: "bambro", id: "https://open.spotify.com/embed/track/5EGJ7e7frJjYja6H4afzoT?utm_source=generator" },
  { name: "juke jam", id: "https://open.spotify.com/embed/track/3eze1OsZ1rqeXkKStNfTmi?utm_source=generator" },
  { name: "hurricane", id: "https://open.spotify.com/embed/track/0RbW8kWozrVSIGb4V13o0o?utm_source=generator" },
  { name: "be", id: "https://open.spotify.com/embed/track/11aFVmFy8903RwDx4ohQiq?utm_source=generator" },
  { name: "soul sista", id: "https://open.spotify.com/embed/track/54SqlaPV5mzRTBBWdnE1OO?utm_source=generator" },
  { name: "cream", id: "https://open.spotify.com/embed/track/119c93MHjrDLJTApCVGpvx?utm_source=generator" },
  { name: "keep ya head up", id: "https://open.spotify.com/embed/track/0XRbYXQUymj9SJkrr8YK5B?utm_source=generator" },
];
