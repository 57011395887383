import React from "react";
import styled from 'styled-components';
import { songs } from './songs';
import { getISOWeek } from 'date-fns';


const Row = styled.div`
  height: 100%;
  background-color: #000000;
  background-image: linear-gradient(160deg, #000000 16%, #0d2620 100%);

  display: inline-block;
  padding: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
`;

const Column = styled.div`
  height: 100%;
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Text = styled.p`
  color: white;
  font-size: 24px;
  text-align: justify;
  margin-bottom: 40px;
`;

export const Year2024 = () => {
  const style = {
    border: "unset",
  };

  const currentWeek = getISOWeek(new Date()) % songs.length;
  const songUrl = songs[currentWeek].id;

  return (
    <Row>
      <Column>
        <Text>A new song every Monday. Picked just for you.</Text>

        <iframe
          title="spotify embed"
          style={style}
          src={songUrl}
          width="255"
          height="70%"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture">
        </iframe>
      </Column>
    </Row>
  );
}

